// Main javascript entry point
// Should handle bootstrapping/starting application





$(document).ready(function () {

  //owl carousel
  $(".operator-wrap").owlCarousel({
      loop: true,
      dots: false,
      nav: false,
      margin: 50,
      // autoplay: true,
      // smartSpeed: 1000,
      // autoplayTimeout: 2000,
      navClass: ['owl-prev', 'owl-next'],
      navText: false,
      responsive: {
          0: {
              items: 1
          },
          320: {
              items: 2
          },
          600: {
              items: 4
          },
          800: {
              items: 6
          },
          1000: {
              items: 7
          }
      }
  });

    new WOW().init();

    //modal
    $('.btn-modal').on('click', function () {
        $('.modal').fadeIn();
        $('.overlay').fadeIn();
    })

    $('.overlay, .modal-close').on('click', function () {
        $('.modal').fadeOut();
        $('.overlay').fadeOut();
    })
});